.account-list{
	
}
.account-list table{
	/*min-width: calc(100% - 30px) !important;*/
}
.account-list p{
	margin: 0px !important;
}
.account-list thead tr th{
	font-size: 13px !important;
	font-weight: bold;
}
.account-list tbody tr:hover a,.account-list tbody tr:hover{
	color: #ff9e05 !important;
}
.account-list tbody tr td{
	background-color: unset;
}
.account-list tbody tr:nth-child(odd){
	background-color: #b7b7b724 !important;
}
.account-list .ant-badge-count{
	background-color: #007fe4 !important;
    color: white !important;
    font-size: 10px !important;
    font-weight: bold !important;
}
.blk-p-10{
	padding:  10px;
}
.blk-p-20{
	padding:  20px;
}
.blk-row{
	position: relative;
}
.blk-f-left{
	float: left;
}
.blk-f-right{
	float: right;
}
.account-pagi{
	margin: 20px 0px;
}
.account-pagi .ant-pagination-item-active{
	overflow: hidden !important;
	border: unset !important;
}
.account-pagi .ant-pagination-item-active a{
	background: #f4af40 !important;
	color: #ffffff !important;
    font-weight: bold !important;
    line-height: 32px;
}
.account-pagi .ant-pagination-item:hover {
    border-color: #f4af40;
    transition: all 0.3s;
}
.account-pagi .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgb(255 255 255);
    font-weight: 600;
    background-color: #f4af40;
}
.account-detail-header,.contract-detail-header{
	font-size: calc(2vw - 2px) !important;
	padding: 20px 10px !important;
}
.account-detail-header span:hover,.contract-detail-header span:hover{
	color: #f4af40;	
}

.account-detail-content{
	margin-bottom: 20px;
}
.account-detail-content tr td:nth-child(1){
	width: 50%;
	font-weight: bold;
}
.account-detail-content tr td:nth-child(2){
	padding-left: 10px;
}
.account-detail-power{

}
.account-detail-menu .ant-menu-item-selected::after{
	border-bottom: 2px solid #ff9800 !important;
	bottom: 10px !important;
}
.contract-detail-content .contract-detail-general{
 	display: flex;
 	justify-content: space-between;
 	margin-bottom: 20px;
}
.contract-detail-general .contract-detail-general-title{
	font-size: 18px;
	color: #ff9800;
}
.create-smart-contract .smart-contract-address a,.create-smart-contract .smart-contract-from a{
	line-break:  anywhere;
}
.transaction-detail-card .transaction-detail-card-address{
	line-break:  anywhere;	
}
.transaction-detail-card .transaction-detail-card-address:hover{
	color: #ff9800;
}
.trigger-smart-contracts, .transfer-smart-contracts{
	padding-bottom: 20px;
}
.trigger-smart-contracts .smart-contract-address, .transfer-smart-contracts .smart-contract-address{
	line-break:  anywhere;
}
.contract-transaction-table{
	padding-top: 20px;
	padding-bottom: 20px;
}
.contract-transaction-table .contract-transaction-table-total p{
	padding-bottom: unset;
	line-height: 32px;
	margin-bottom: unset;
}
.contract-transaction-table .contract-transaction-table-date{
	float: right;
	padding: 10px;
}
.contract-transaction-table .contract-transaction-table-total{
	float: left;
	padding: 10px;
}
.account-transfer{
	text-align: center;
}
.account-transfer-choose{
	position: relative;
	float: left;	
}
.account-transfer-date{
	position: relative;
	float: right;
}
.account-transfer-table-total p{
	padding:  10px;
	margin-bottom: unset;
}

@media (max-width:  768px){

	.ant-table-container table td{
		padding: 7px !important;
	}	
	.account-detail-header,.contract-detail-header{
		font-size: 16px !important;
		line-break:  anywhere;
	}
	.account-detail-power{
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.account-list tbody tr td{
		font-size: 13px !important;
		padding: 7px !important;
	}
	.contract-detail-general .contract-detail-general-title{
		font-size: 15px;
		color: #ff9800;
	}	
	.contract-transaction-table .contract-transaction-table-date, .contract-transaction-table .contract-transaction-table-total{
		padding: 3px 10px;
	}
	.contract-transaction-table .contract-transaction-table-total{
		float: right;
	}	
	.contract-transaction-table .contract-transaction-table-total p, .account-transfer-table-total p{
		line-height: unset;
		text-align: center;
	}
	.account-transfer-choose,.account-transfer-date{
		float: unset;	
		margin: 0 auto;
	}
	.account-transfer-choose{
		padding-bottom: 10px;
	}
	.account-transfer-table-total p{
		margin: 0 auto;
	}
}

