.react-sweet-progress {
  display: block;
}
.react-sweet-progress-line {
  background-color: #cdcdcd;
}
.react-sweet-progress-line-inner {
  min-height: 8px;
}
.react-sweet-progress-symbol {
  display: none;
}
