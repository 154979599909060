.ant-menu-horizontal {
  line-height: 80px;
}
body {
  background: #f6f7fb;
  color: black;
}

#root,
#root > span {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.text-right {
  text-align: end;
}
.text-center {
  text-align: center;
}

// remove autofill color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.ant-table-thead {
  height: 40px;
  line-height: 1;
  & > tr > th {
    padding: 5px 16px;
  }
}
.ant-list-item{
  color: black !important;
  font-weight: normal !important;
}

.modal-become-witness {
  text-align: center;
  .ant-modal-confirm-content {
    margin-top: 25px;
  }
  .ant-modal-confirm-btns {
    float: none;
    .ant-btn-primary {
      width: 100%;
    }
  }
}

.ant-affix {
  background-color: #ffffff;
  padding: 15px;
  border: 1px solid #dedede;
  height: 65px !important;
  @media screen and (max-width: 576px) {
    height: 110px !important;
  }
}

.mb-15 {
  margin-bottom: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}
